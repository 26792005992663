import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import { GatsbySeo } from 'gatsby-plugin-next-seo';

import archiveStyles from "./styles/archives.module.scss"

const SinglePost = ({ title, link, date }) => {
  return(
    <Link to={link} className={archiveStyles.singlePost}>
    <p>{title.toUpperCase()}</p>
    <div className={archiveStyles.dateContainer}>
    <p className={archiveStyles.date}>{date.toUpperCase()}</p>
    </div>
    </Link>
  )
}

const Posts = ({ posts }) => {
  var date= undefined;
  return(
    <>
    {posts.map((post, index) => {
      if(date !== post.node.frontmatter.date.split("/")[1]){
        date = post.node.frontmatter.date.split("/")[1];
        return(
          <>
          <div key={"posts-key-"+index} className={archiveStyles.divideContainer}>
          <p> {post.node.frontmatter.date.split(" / ").slice(0,-1).join("-")} </p>
          <div className={archiveStyles.divideSplit}>
          <hr className={archiveStyles.postDivide} />
          </div>
          </div>
          <SinglePost 
            title={post.node.frontmatter.title} 
            link={post.node.fields.slug}
            date={post.node.frontmatter.date}
            key={"postList"+index}
          />
          </>
        )
      }

      return(
        <>
          <SinglePost 
            title={post.node.frontmatter.title} 
            link={post.node.fields.slug}
            date={post.node.frontmatter.date}
          />
        </>
      )
    })}
    </>
  )
}

const SecondPage = ({data}) => {
  return (
  <Layout>
    <GatsbySeo
        title="Archive"
        description="List of posts."
        openGraph={{
          url:'https://www.simon.sekavcnik.com/archive',
          title: "Archive",
          description: "List of posts.",
        }}/>
    <div className={archiveStyles.titleContainer}>
      <h1>Archive</h1>
    </div>
    <hr className={archiveStyles.divide}/>
    <div className={archiveStyles.postContainer}>
      <Posts posts={data.allMarkdownRemark.edges}/>
    </div>
  </Layout>
  )
}

export default SecondPage

export const allPost = graphql`
  query allPosts {
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
    ) {
      edges {
        node {
          frontmatter{
            title,
            date(formatString: "YYYY / MMM / DD"),
            abstract
          }
          fields{
            slug
          }
        }
      }
    }
  }
`
